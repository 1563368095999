export const environment = {
  production: false,
  // urlAddress: 'https://localhost:7238'
  // urlAddress: 'http://localhost:5080' 
 
  // urlAddress: "https://kline-great-api.greatlab.io",
  // urlAddress: 'https://api-demo.greatlab.io'
  // urlAddress: 'https://api-us.greatlab.io'    
  // urlAddress: 'https://api-aus.greatlab.io'    
  // urlAddress: 'https://api-uk.greatlab.io'   
  // urlAddress: 'https://api-staging.greatlab.io'
  urlAddress: 'https://portal-api.greatlab.io'
  // urlAddress: 'https://api-demo.greatlab.io'
  // urlAddress: 'https://api-demo.greatlab.io'
  // urlAddress: 'https://als-api.greatlab.io'
};
  