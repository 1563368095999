import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { finalize } from 'rxjs/operators';
import { OrderDetailModel, OrderServiceProxy } from '../../../../shared/service-proxies/event-service-proxy';
import { convertFromFDI } from '../../../@core/models/TeethType';
import { AuthenticationService } from '../../../@core/service';
import { OrderSerice } from '../../../pages/orders/order.service';
import { IMPRESSIONS } from '../../helpers/impressions';

@Component({
  selector: 'ngx-print-order-docket',
  templateUrl: './print-order-docket.component.html',
  styleUrls: ['./print-order-docket.component.scss']
})
export class PrintOrderDocketComponent implements OnInit, AfterViewInit {
  @Input() orderId: any = "eb7a693a-0c9e-4315-e6e0-08dc23fea9ce";
  @Input() code: any;
  @Input() isDocket: boolean;
  @Output() loadComplete = new EventEmitter<void>();

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComplete.emit();
    }, 1000);
  }

  orderDto: OrderDetailModel = new OrderDetailModel();
  productInfo: any = {
    data: null
  }
  user: any = {};
  arch: any = {
    upper: false,
    lower: false
  }



  getCurrentScan(id: any) {
    if (id) {
      return IMPRESSIONS.find(x => x.id == id).name;
    }
  }

  constructor(
    private _orderService: OrderServiceProxy,
    public orderHelperService: OrderSerice,
    private _authenticationService: AuthenticationService,
  ) { 
    let currentUser = this._authenticationService.currentUserValue;
      let helper = new JwtHelperService();
      this.user = helper.decodeToken(currentUser.accessToken);

  
 



  }
  checkIsOrthoColor() {
    let result = this.orderDto.orderProducts.filter(x => x.product.subcategoryId == 36);
    if (result.length > 0) {
      return true
    } else {
      return false
    }
  }

  checkIsOrtho() {
    let result = this.orderDto.orderProducts.filter(x => x.product.subcategoryId == 34);
    if (result.length > 0) {
      return true
    } else {
      return false
    }
  }

  isDeffaultFiitertemplate: boolean = false;
  ngOnInit(): void {
    this._orderService.getDetailsById(this.orderId).pipe(
      finalize(() => {
     
      }))
    .subscribe(
      (result) => { 
        this.orderDto = result;
        let arches = this.orderDto.orderProducts.find(x => x.product.subcategoryId == 36)?.arch;
        if (arches) {
          const archParts = arches.split(",").map(part => part.trim());
          const archArr = {};
          archParts.forEach(part => {
              archArr[part] = true;
          });
          this.arch = archArr;
        }
        if (!this.orderDto.locationDocumentSettings || this.orderDto.locationDocumentSettings?.docketFooter.length == 0) {
          this.isDeffaultFiitertemplate = true;
          this.orderDto.locationDocumentSettings = null;
        } 
      },
      (error: any) => {}
    )
  }
  docketTermsConditions: string = `This is a custom-made medical device that has been manufactured to satisfy the design characteristics and properties specified by the prescriber for the above named patient. This medical device is intended for exclusive use by this patient and conforms to the relevant essential requirements specified in Annex 1 of the Medical Devices Directive and the United Kingdom Medical Devices Regulations. This statement does not apply to medical devices that have been repaired and/or refurbished for an individual patients use.`;
  docketFooter: string = `2023 GreatLab Ltd, Registered in England and Wales - <b> Company No. 411111</b>`;
  docketFooterLogo: string = `../../../../assets/images/icons/damas_black.png`;
  convertTeethNotation(system: number, toothNumberStr: string): string {
    const toothNumbers = toothNumberStr.split(',');
    const convertedTeethNumbers = toothNumbers.map(toothStr => {
      const toothNumber = parseInt(toothStr, 10); 
      if (!isNaN(toothNumber)) {
        return convertFromFDI(system, toothNumber);
      } else {
        console.error('Invalid tooth number:', toothStr);
        return ''; 
      }
    }).join(', '); 
  
    return convertedTeethNumbers;
  }


  elementType = 'svg';
  value = 'someValue12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 2;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  
  get values(): string[] {
    return this.value.split('\n');
  }
  codeList: string[] = [
    '', 'CODE128',
    'CODE128A', 'CODE128B', 'CODE128C',
    'UPC', 'EAN8', 'EAN5', 'EAN2',
    'CODE39',
    'ITF14',
    'MSI', 'MSI10', 'MSI11', 'MSI1010', 'MSI1110',
    'pharmacode',
    'codabar'
  ];

}
