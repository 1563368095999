import { Component, Input, OnInit } from '@angular/core';
import { BillingServiceProxy, DoctorSummaryModel } from '../../../../shared/service-proxies/event-service-proxy';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../@core/service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'ngx-modal-pay-doctor-financials',
  templateUrl: './modal-pay-doctor-financials.component.html',
  styleUrls: ['./modal-pay-doctor-financials.component.scss']
})
export class ModalPayDoctorFinancialsComponent implements OnInit {

  @Input() summary: DoctorSummaryModel;
  @Input() stripeAccount: string;
  @Input() returnUrl: string;
  amount: number;
  note: string;

  user: any;

  constructor(
    protected dialogRef: NbDialogRef<ModalPayDoctorFinancialsComponent>,
    private _billingService: BillingServiceProxy,
    private _authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let currentUser = this._authenticationService.currentUserValue;
    let helper = new JwtHelperService();
    this.user = helper.decodeToken(currentUser.accessToken);

    if(this.user.role == 'Doctor' || this.user.role == 'Manager') {
      this.amount = this.summary.amount;
    }
  }

  pay() {
    const returnUrl = this.returnUrl;
    this.router.navigate(['/pages/billing/pay'], { queryParams: { type: 'amount', dentistId: this.summary.doctor.id, practiceId: this.summary.practice.id, amount: this.amount, note: this.note, returnUrl: returnUrl, stripeAccount: this.stripeAccount } });

    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
