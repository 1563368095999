<nb-card class="modal-card">
    <nb-card-header>
        <div class="modal-title">
            Paying balance of {{summary.doctor.fullName}}, {{summary.practice.name}}
        </div>
    </nb-card-header>
    <nb-card-body class="modal-body">
        <p class="body-text">Amount due: {{summary.amount | currency : summary.currencyCode : "symbol"}}</p>
        <div class="amount-input">
            <p class="body-text">Pay amount:</p>
            <input nbInput type="number" [(ngModel)]="amount" placeholder="0.00" class="number-input" />
        </div>
        <p class="body-text" *ngIf="summary.amount - amount < 0">Overpayment - Credit note of {{ amount -
            summary.amount | currency : summary.currencyCode : "symbol"}} will be created</p>
        <textarea class="note" placeholder="Notes.." [(ngModel)]="note"></textarea>
    </nb-card-body>
    <nb-card-footer>
        <div class="control-buttons d-flex">
            <button class="btn-control" nbButton size="small" (click)="cancel()">
                Cancel
            </button>
            <button [disabled]="!amount || amount <= 0" class="btn-pay" nbButton status="primary" size="small"
                (click)="pay()">
                Pay
            </button>
        </div>
    </nb-card-footer>
</nb-card>