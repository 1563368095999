import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbIconConfig, NbToastrService } from '@nebular/theme';
import { CreateLocationExportGroupModel, LocationServiceProxy, ResultMessage } from '../../../../shared/service-proxies/event-service-proxy';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DeleteModalApproveComponent } from '../delete-modal-approve/delete-modal-approve.component';

@Component({
  selector: 'ngx-practice-group-modal',
  templateUrl: './practice-group-modal.component.html',
  styleUrls: ['./practice-group-modal.component.scss']
})
export class PracticeGroupModalComponent implements OnInit {
  creageGroupModel: CreateLocationExportGroupModel = new CreateLocationExportGroupModel()
  @Input() locationId: any;
  form: FormGroup;
  groupName: string;
  constructor(
    protected dialogRef: NbDialogRef<PracticeGroupModalComponent>,
    private _locationServiice: LocationServiceProxy,
    private formBuilder: FormBuilder,
    private toastrService: NbToastrService,
  ) { }


  create() {
    this.creageGroupModel.locationId = this.locationId;
    this.creageGroupModel.name = this.groupName;
    this._locationServiice.createExportGroup(this.creageGroupModel).pipe(
      finalize(() => {
      }))
      .subscribe(
        (result: CreateLocationExportGroupModel) => {
          this.dialogRef.close(result)
          
        },
        (error: any) => {
        }
      )
  }










 
  

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
    });
  }
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  close() {
    this.dialogRef.close(false);
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
     this.create();
    } else {
      this.validateAllFormFields(this.form);
    }
  }




}
