import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbIconConfig, NbToastrService } from '@nebular/theme';
import { ManualInvoiceResponseModel, OrderDetailModel, OrderServiceProxy, ProductPricePer } from '../../../../shared/service-proxies/event-service-proxy';
import { finalize } from 'rxjs/operators';
import { CategoryNameEnumModel } from '../category-enum-modal';
import { convertFromFDI } from '../../../@core/models/TeethType';
import { PrintService } from '../../../@core/service';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-manual-invoice-modal',
  templateUrl: './manual-invoice-modal.component.html',
  styleUrls: ['./manual-invoice-modal.component.scss']
})
export class ManualInvoiceModalComponent implements OnInit {
  @Input() orderId: any;
  @Input() extraLogic: any
  orderDto: OrderDetailModel = new OrderDetailModel();
  isLabDocket: boolean = true;
  isInvoice: boolean = true;
  manualOrder: ManualInvoiceResponseModel = new ManualInvoiceResponseModel();
  patientStatment: boolean = true;
  isGenerated: boolean = false;
  constructor(
    private router: Router,
    private __orderService: OrderServiceProxy,
    public printService: PrintService,
    private toastrService: NbToastrService,
    protected dialogRef: NbDialogRef<ManualInvoiceModalComponent>
  ) { }

  ngOnInit(): void {
    
    this.getOrderDetials();
    
  }
  selectProduct(e: any ) {

  }
  close() {
    this.dialogRef.close(false);
  }
  onPrint() {
    this.onPrintInvoice();
  }

  createInvoice() {
    this.isPrintingDone = true;
    this.__orderService.createInvoiceForOrder(this.orderId).pipe(
    finalize(() => {
      this.isPrintingDone = false;
    }))
    .subscribe(
      (result: ManualInvoiceResponseModel) => {
        this.manualOrder = result;
        this.isGenerated = true;
        if (this.isLabDocket || this.isInvoice ||  this.patientStatment) {
          this.onPrintInvoice();
        } else {
          this.isPrintingDone = false;
        }
      },
      (error: any) => { 
        const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
        this.toastrService.danger('', "Manual Invoice Error!", { icon: iconConfig });
        
      }
    )
}

  totalPrice: number = 0;
  onSubmit() {
    this.createInvoice();
  }
  getOrderDetials() {
    this.isPrintingDone = true;
    this.totalPrice = 0;
    this.__orderService.getDetailsById(this.orderId).pipe(
      finalize(() => {
        this.isPrintingDone = false;
      }))
      .subscribe(
        (result: any) => {
          this.orderDto = result;
          let tmpArr = this.orderDto.orderProducts.filter(x => !x.isInvoiced);
          tmpArr.forEach(x => {
            this.totalPrice += x.product.price;
          })
          this.generateBasketFields();
        },
        (error: any) => { }
      )
  }
  getCurrentNameCategory(categoryId: number) {
    return CategoryNameEnumModel[`category_${categoryId}`]
  }
  convertTeethNotation(system: number, toothNumberStr: string): string {
    const toothNumbers = toothNumberStr.split(',');
    const convertedTeethNumbers = toothNumbers.map(toothStr => {
      const toothNumber = parseInt(toothStr, 10); 
      if (!isNaN(toothNumber)) {
        return convertFromFDI(system, toothNumber);
      } else {
        console.error('Invalid tooth number:', toothStr);
        return ''; 
      }
    }).join(', '); 
  
    return convertedTeethNumbers;
  }


  isPrintingDone: boolean = false;

  onPrintInvoice() {
    let isCreateInvoice = this.orderDto.orderProducts.filter(x => !x.isInvoiced);
    if (isCreateInvoice.length == 0) {
      const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
      this.toastrService.danger('', "Error!", { icon: iconConfig });
      return false;
    }
    let data = {
      invoiceId: this.manualOrder.invoiceId,
      statmentId: this.manualOrder.statementId,
      orderId: this.orderId,
      isDocket: this.isLabDocket,
      isInvoice: this.isInvoice,
      isStatment: this.patientStatment
    }
    this.isPrintingDone = true;
    this.printService.printDocument("invoice", JSON.stringify(data), () => {
      // this.getOrderDetials();
      this.isPrintingDone = false;
      // Additional logic after printing can be placed here.
    });
  }

  routeToOrderEdit(order: any) {
    this.dialogRef.close(false);
    if (this.extraLogic == 'list') {
      this.router.navigate([`/pages/orders/update/${order.id}/category/${order.categoryId}`],{ queryParams: { extraEdit: true, pach: `manual_invoice` } });
    } else {
      this.router.navigate([`/pages/orders/update/${order.id}/category/${order.categoryId}`],{ queryParams: { extraEdit: true, pach: `manual_invoice_details` } });
    }
  }
  price: any;
  shipping: any;
  total: any;
  generateBasketFields() {
    this.price = 0;
    this.shipping = 0;
    this.total = 0;
    this.orderDto.orderProducts.filter(x => !x.isInvoiced).map((item: any) => {
      var countIt = 0;
      switch (item.product.pricePer) {
        case ProductPricePer.Tooth:
             countIt = item.teeth?.split(',').length ? item.teeth?.split(',').length : 1;
        
          break;
        case ProductPricePer.Arch:
         countIt = item.arch?.split(',').length ? item.arch?.split(',').length : 1;
       
          break;
        case ProductPricePer.Product:
          countIt = 1;
          break;
      }
      if (item.product.customPrice !== 0) {
        item.itemTmp = countIt * item.product.customPrice * item.quantity;
      } else {
        item.itemTmp = countIt * item.product.price * item.quantity;
      }
      item.coutQt = countIt;
      this.price += item.itemTmp;
      return item;
    })
    this.orderDto.orderCustomFees?.filter(x => !x.isInvoiced).map((fee: any) => {
      var countIt = 1;
      fee.itemTmp = (countIt * fee.amount) * 1;
      this.price += fee.itemTmp;
    })
  }
}
