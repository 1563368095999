import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-toggle-switch-button',
  templateUrl: './toggle-switch-button.component.html',
  styleUrls: ['./toggle-switch-button.component.scss']
})
export class ToggleSwitchButtonComponent implements OnInit, OnChanges {
  @Output() outPutEvents = new EventEmitter<any>();
  @Input() toggleVal: boolean = false;
  // toggleVal: boolean = false;
  constructor() { }

  ngOnInit(): void {
    // this.toggleVal = this.value;

  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  update(e:any) {
    this.outPutEvents.emit(e.target.checked)
    
  }
}
