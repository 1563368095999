import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { InternalNoteModel } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service/authentication.service';
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: 'ngx-internal-notes',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './internal-notes.component.html',
  styleUrls: ['./internal-notes.component.scss']
})
export class InternalNotesComponent implements OnInit {

  constructor(
    private _authenticationService: AuthenticationService,
  ) {
    let currentUser = this._authenticationService.currentUserValue;
    let helper = new JwtHelperService();
    this.userId = helper.decodeToken(currentUser.accessToken).userid.toLowerCase();
    this.fullUserName = helper.decodeToken(currentUser.accessToken).fullName;
  }

  @Input() internalNotes: InternalNoteModel[] = [];
  @Input() dateFormat: string = 'dd/MM/yy';
  @Output() onNewInternalNote: EventEmitter<string> = new EventEmitter<string>();
  @Output() onInternalNoteUpdate: EventEmitter<InternalNoteModel> = new EventEmitter<InternalNoteModel>();
  newInternalNote: string = '';
  userId: string;
  fullUserName: string;
  public get now() : Date {
    return new Date();
  }
  

  ngOnInit() {
    // this.internalNotes.forEach(x => {
    //   x.createdAt = new Date(x.createdAt + '+00:00');
    // })
  }

  onNewInternalNoteSubmit(e) {
    e.preventDefault();
    this.onNewInternalNote.emit(this.newInternalNote);
    this.newInternalNote = '';
  }

  onInternalNoteUpdateSubmit(e, internalNote: InternalNoteModel) {
    e.preventDefault();
    this.onInternalNoteUpdate.emit(internalNote);
  }
}
