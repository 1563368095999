import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPrintService, PrintOptions } from 'ngx-print';
@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;
  private callback: () => void; // Add a property to store the callback

  constructor(
    private router: Router,
    private printServiceLib: NgxPrintService,
    ) {}

  printDocument(documentName: string, documentData: any, callback: () => void) {
    this.isPrinting = true;
    // const documentIds = documentData.join(',');
    this.callback = callback;
    
    // this.router.navigate(['/',{ outlets: { print: ['print', 'invoice', documentIds] } }]);
    this.router.navigate([
      '/',
      {
        outlets: {
          print: ['print', documentName, documentData],
        },
      },
    ]);
  }
  errorPrint() {
    
    if (this.callback) {
      this.callback(); // Invoke the callback function
    }
  }


  printDocket() {
    const customPrintOptions: PrintOptions = new PrintOptions({
      printSectionId: 'manualInvoiceDoket',
      useExistingCss: true,
    });
    this.printServiceLib.print(customPrintOptions)
  }

  onDataReady() {
    setTimeout(() => {
     this.printDocket();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);

      if (this.callback) {
        this.callback(); // Invoke the callback function
      }
    });
  }
}
