import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { DATA_CONFIG } from '../data-reports/data-report-config';
@Injectable({
  providedIn: 'root'
})
export class DataMappingReportsService {
  productReportData: any;
  dailyReportsData: any;
  periodReportData: any;
  agedBalancesReportData: any;

  constructor() {}

  private mapData(data: any[], config: any): any[] {
    const fields = config.fields;
    const totalsLabel = config.totalsLabel;
  
    // Map the data
    const result = data.map(item => 
      Object.keys(fields).reduce((acc, key) => {
        acc[key] = item[fields[key]] || '';
        return acc;
      }, {})
    );
  
    // Create the totals row
    const totalsRow = {
      ...Object.keys(fields).reduce((acc, key) => {
        acc[key] = ''; // Initialize all fields as empty
        return acc;
      }, {}),
      [Object.keys(fields)[0]]: totalsLabel,
      ...Object.keys(fields).reduce((acc, key) => {
        // Compute totals here if needed, otherwise leave as empty
        if (data.some(d => d[fields[key]] !== undefined)) {
          acc[key] = data.reduce((sum, d) => sum + (d[fields[key]] || 0), 0);
        }
        return acc;
      }, {})
    };
  
    result.push(totalsRow);
  
    return result;
  }
  

  convertDataProduct(data: any[]): any[] {
    return this.mapData(data, DATA_CONFIG.product);
  }

  convertDataDaily(data: any[]): any[] {
    return this.mapData(data, DATA_CONFIG.daily);
  }

  convertDataPeriod(data: any): any[] {
    return this.mapData(data.items, DATA_CONFIG.period);
  }

  convertDataAged(data: any): any[] {
    return this.mapData(data.items, DATA_CONFIG.aged);
  }

  generateDataToExport(type: any) {
    switch (type) {
      case 2:
        return this.convertDataProduct(this.productReportData.itemsGroups);
      case 3:
        return this.convertDataDaily(this.dailyReportsData.itemsGroups);
      case 4:
        return this.convertDataPeriod(this.periodReportData);
      case 7:
        return this.convertDataAged(this.agedBalancesReportData);
      default:
        return [];
    }
  }

  exportCSV(data: any[], headers: string[], fileName: string, title: string) {
    const options = {
      filename: fileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: title,
      useTextFile: false,
      useBom: true,
      headers: headers,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  shortDate(date: any) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }
}