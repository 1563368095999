import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize } from 'rxjs/operators';
import { ReceivingSettingsModel, ReceivingSettingServiceProxy, OrderServiceProxy, LocationServiceProxy, LocationModelPagedListDto, LocationModel, AuthServiceProxy, UserDetailsIntecom } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService, IntegrationService } from '../../../@core/service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DynamicToAddComponent } from '../popover-dynamic/dynamic.components';

@Component({
  selector: 'ngx-change-location',
  templateUrl: './change-location.component.html',
  styleUrls: ['./change-location.component.scss'],
  providers: [IntegrationService]
})
export class ChangeLocationComponent implements OnInit, AfterViewInit  {
  @ViewChild('selectDropdownLocation') selectDropdown: NgSelectComponent;
  @ViewChild('locationClick', { static: true }) locationClick: ElementRef;
  @Input() data: any;
    popoverInfoComponent = DynamicToAddComponent;
  tmpArrayOption: any[] = [];
  isReceivedLoading: boolean = false;
  locationItems: LocationModel[] = [];
  searchLocation: string = '';
  userData: UserDetailsIntecom = new UserDetailsIntecom();
  locationId: any;
  selectedLocation: any;
  user: any = {};
 
  constructor(
    private _receivingService: ReceivingSettingServiceProxy,
    private _authenticationService: AuthenticationService,
    private _orderService: OrderServiceProxy,
    private _authService: AuthServiceProxy,
    private _locationService: LocationServiceProxy,
    private _integrationService: IntegrationService
  ) { 
   




    let currentUser = this._authenticationService.currentUserValue;
      let helper = new JwtHelperService();
      this.user = helper.decodeToken(currentUser.accessToken);
      this._authService.getUsersDetailsForIntercomById().pipe(
        finalize(() => {
        }))
      .subscribe(
        (result:UserDetailsIntecom) => { 
          this.userData = result;
          
        },
        (error: any) => {}
      )
  }

  isLoader: boolean = false;
ngAfterViewInit(): void {
}



checkIsLocation(): void {
  if (localStorage.getItem('selectLocation')) {
    this.selectedLocation = JSON.parse(localStorage.getItem('selectLocation'));
    this.locationId = this.selectedLocation.id;
  } else {
    if (this.locationClick) {
      this.locationClick.nativeElement.click();
      setTimeout(() => {
        const myCustomClass: string ="overlay-opened-ng-panel"
        const panel = document.querySelector('.ng-dropdown-panel');
        panel.classList.add(myCustomClass);
      }, 100);

    }
  }
}
onClick() {
}
  ngOnInit(): void {
   this.getLocations();
  }
 

 getLocations() {
  this._locationService.getAll(this.user.companyId, 1, 100, this.searchLocation).pipe(
    finalize(() => {
     
        if (this.locationItems.length > 1) {
          this.checkIsLocation();
        } else {
          const panel = document.querySelector('#selected_location');
          panel.classList.add('d-none');
          this.selectedLocation = this.locationItems[0];
          localStorage.setItem('selectLocation', JSON.stringify(this.selectedLocation));
          this._integrationService.getIntercomData(this.data, this.user, this.selectedLocation);
        }
    
    }))
  .subscribe(
    (result: LocationModelPagedListDto) => { 
      this.locationItems = result.items;
    },
    (error: any) => {}
  )
 }
  onCloseDropdown() {
    this.selectDropdown.close();
}
  onShowDropdown() {
    this.selectDropdown.open();
  }
 
  mouseSetterType(type: any) {
   
  }

  selectReceiving(type: any) {
   

  }

  updateOrder() {
  
  }

 
  onSelectClick(event: any) {
    this.onCloseDropdown();
  }
  selectLocation() {
    this.selectedLocation = this.locationItems.find(x => x.id == this.locationId);
    localStorage.setItem('selectLocation', JSON.stringify(this.selectedLocation));
    
    this.selectDropdown.close(); 
    window.open('./pages/dashboard', '_self');
  }
 
}
