<div class="d-flex flex-column">
    <textarea name="dentist-internal-note" id="dentist-internal-note" class="internal-note-textarea"
        placeholder="Add a note" (keydown.enter)="onNewInternalNoteSubmit($event)"
        [(ngModel)]="newInternalNote"></textarea>
    <div class="note-container d-flex mt-2 position-relative"
        *ngFor="let internalNote of internalNotes.slice().reverse()"
        nbTooltip="{{(internalNote.id ? internalNote.createdAt + '+00:00' : now) | date: dateFormat + ', hh:mma'}} {{internalNote.id ? internalNote.creatorFullName : fullUserName}}"
        nbTooltipStatus="control">
        <div class="creator-avatar-container">
            <img src="{{internalNote.creatorProfilePictureURL || './assets/images/icons/person.svg'}}" alt="">
        </div>
        <textarea name="dentist-internal-note" id="dentist-internal-note" class="internal-note-textarea existing-note"
            placeholder="Add a note" (keydown.enter)="onInternalNoteUpdateSubmit($event, internalNote)"
            [(ngModel)]="internalNote.contents" [disabled]="internalNote.createdById !== userId"></textarea>
    </div>
</div>