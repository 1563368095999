import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { finalize } from 'rxjs/operators';
import { UserDetailsIntecom, AuthServiceProxy } from '../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from './authentication.service';
import { PosthogService } from './posthog/posthog.service';
@Injectable({
    providedIn: 'root',
  })
export class IntegrationService {
    private secretKey = "Rd9kdLyYm055Af4uNkq5-sDFiyOyw0ZpD_HfNLsy";
    data: UserDetailsIntecom = new UserDetailsIntecom();
    selectedLocation: any = null;
    constructor(
      private posthogService: PosthogService
    ) {
    }
   
 

getIntercomData(data: any, user: any, activeLocation: any) {
    this.selectedLocation = activeLocation;
    this.setupIntercom(data, user)
    this.setupPendo(data, user)
    this.setupLogRocket(data, user)
    this.setupPosthog(data, user)
}
    setupIntercom(data: any, user: any) {
      const key = (<any>window).CryptoJS.HmacSHA256(user.userid, this.secretKey).toString();
  
      (<any>window).Intercom('boot', {
        app_id: "e26kmq5v",
        email: user.email,
        user_id: user.userid,
        name: data.fullName,
        fullName: data.fullName,
        locations: this.selectedLocation?.name ? this.selectedLocation?.name : data.company,
        username: data.username,
        role: user.userroles,
        company_group: data.company,
        companies: [],
        user_hash: key,
      });
  
      (<any>window).Intercom('update', {
        user_hash: key,
        user_id: user.userid,
        locations: this.selectedLocation?.name,
        active_location: this.selectedLocation?.name ? this.selectedLocation?.name : data.company,
        company: {
          company_id: this.selectedLocation?.id ? this.selectedLocation?.id : user.companyId,
          name:  this.selectedLocation?.name ? this.selectedLocation?.name : data.company,
          industry: data.company
        }
      });
      console.log('Intercom connected');
    }
  
    setupPendo(data: any, user: any) {
      (<any>window).pendo.initialize({
        account: {
          email: user.email,
          user_id: user.userid,
          name: user.email,
          role: user.userroles,
          company: user.companyId ? user.companyId : "none",
        }
      });
      console.log('pendo connected');
    }
  
    setupLogRocket(data: any, user: any) {
      (<any>window).LogRocket.identify(user.userid, {
        name: user.email,
        email: data.fullName,
        subscriptionType: 'pro'
      });
      console.log('LogRocket connected');
    }
  
    setupPosthog(data: any, user: any) {
      this.posthogService.identifyUser(user.userid);
      const userProperties: any = {
        email: user.email,
        name: data.fullName,
        user_role: user.userroles,
        company_name: data.company,
        company_id: user.companyId
      };
  
      if (this.selectedLocation) {
        userProperties.location_id = this.selectedLocation?.id ?  this.selectedLocation?.id : user.companyId;
        userProperties.location_name = this.selectedLocation?.name ? this.selectedLocation?.name : data.company;
      }
      this.posthogService.updateUserProperties(userProperties);
      this.posthogService.group("company", user.companyId, { name: data.company });
  
      if (this.selectedLocation) {
        this.posthogService.group("location", this.selectedLocation?.id ?? "unknown", { name: this.selectedLocation?.name ?? "unknown" });
      }
      console.log('posthog connected');
      
    }
  }
  