<div style="text-align:center">
	<div class="camera-section">
        <div class="camera-settings-actions">
            <nb-icon *ngIf="!activeSettings" (click)="activeSettings = true" class="setting" icon="settings-2-outline"></nb-icon>
        </div>
            <webcam [height]="300" class="webcam" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
		 [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
		 (initError)="handleInitError($event)"></webcam>

         <div class="captured-section" *ngIf="photo">
            <img class="captured-image" [src]="photo.imageAsDataUrl" *ngIf="photo" alt="Captured Photo">
            <div class="camera-control">
                <nb-icon (click)="savePhoto()" class="take-photo approve" icon="checkmark-outline" ></nb-icon>
                <nb-icon (click)="photo = null"  class="take-photo decline" icon="trash-2-outline" ></nb-icon>
            </div>
        </div>

        <div class="camera-control">
            <nb-icon (click)="triggerSnapshot()" class="take-photo primary" icon="snapshot-camera" pack="great-pack"></nb-icon>
        </div>
         <div [ngClass]="{'active' : activeSettings}" class="camera-settings">
            <div class="left-camera-settings-action">
                <div class="camera-title">
                    Camera Settings
                </div>
                <nb-icon (click)="activeSettings = false" icon="arrow-back-outline"></nb-icon>
            </div>
            <div class="camera-form-group" *ngIf="cameraDevice.length > 0">
                <nb-select fullWidth size="small" placeholder="Camera Device" [(ngModel)]="selectedCamera">
                    <nb-option *ngFor="let item of cameraDevice" [value]="item.deviceId">{{item.label}}</nb-option>
                  </nb-select>
            </div>
         </div>
	</div>
</div>


<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
	<li>{{error | json}}</li>
</ul>