import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateRangeService {

  constructor() { }

  getDateRange(id: number): { start: Date, end: Date } {
    const today = new Date();
    let start: Date;
    let end: Date = today;

    switch (id) {
      case 1: // Today
        start = new Date(today);
        end = new Date(today);
        break;
      case 2: // Last 7 days
        start = new Date(today.setDate(today.getDate() - 7));
        end = new Date();
        break;
      case 3: // Last 4 weeks
        start = new Date(today.setDate(today.getDate() - 28));
        end = new Date();
        break;
      case 4: // Last 3 months
        start = new Date(today.setMonth(today.getMonth() - 3));
        end = new Date();
        break;
      case 5: // Last 12 months
        start = new Date(today.setMonth(today.getMonth() - 12));
        end = new Date();
        break;
      case 6: // Month to date
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date();
        break;
      case 7: // Quarter to date
        start = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
        end = new Date();
        break;
      case 8: // Year to date
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date();
        break;
      case 9: // All time
        start = new Date(1970, 0, 1);
        end = new Date();
        break;
      default:
        start = new Date(today);
        end = new Date(today);
        break;
    }

    return { start, end };
  }
}
