export const DATA_CONFIG = {
    product: {
      fields: {
        group: 'group',
        item: 'item',
        detail: 'detail',
        productCount: 'productCount',
        remakeCount: 'remakeCount',
        remakePercent: 'remakePercent',
        revenue: 'revenue',
        percent: 'percent',
        alloy: 'alloy',
        cogsAlloy: 'cogsAlloy',
        tax: 'tax',
        total: 'total',
      },
      totalsLabel: 'Totals',
    },
    daily: {
      fields: {
        date: 'date',
        clientName: 'clientName',
        practiceName: 'practiceName',
        details: 'details',
        revenue: 'revenue',
        tax: 'tax',
        total: 'total',
        adjust: 'adjust',
        credits: 'credits',
        discount: 'discount',
        payments: 'payments',
      },
      totalsLabel: 'Totals',
    },
    period: {
      fields: {
        group: 'group',
        p1ProductCount: 'p1ProductCount',
        p2ProductCount: 'p2ProductCount',
        varCount: 'varCount',
        p1Revenue: 'p1Revenue',
        p2Revenue: 'p2Revenue',
        varRevenue: 'varRevenue',
        p1Remakes: 'p1Remakes',
        p1RemakesPercent: 'p1RemakesPercent',
        p2Remakes: 'p2Remakes',
        p2RemakesPercent: 'p2RemakesPercent',
      },
      totalsLabel: 'Totals',
    },
    aged: {
      fields: {
        client: 'client',
        practice: 'practice',
        accountCode: 'accountCode',
        phone: 'phone',
        lastPayment: 'lastPayment',
        creditLimit: 'creditLimit',
        balance: 'balance',
        current: 'current',
        _30Days: '_30Days',
        _60days: '_60days',
        _90days: '_90days',
      },
      totalsLabel: 'Totals',
    },
  };
  