<div id="couponPage" #couponPage class="order-content-details" *ngIf="orderDto.id && isDocket">
    <div class="container position-relative h-100 p-0">


        <table class="header-title">
            <colgroup>
                <col style="width:30%">
                <col style="width:50%">
                <col style="width:20%">
            </colgroup>
            <tbody>
                <tr>
                    <td class="center-column">
                        <div class="contact-info">
                            <ul class="contact">
                                <li>
                                    <div class="address">
                                        {{orderDto.location.address}}, {{orderDto.location.city ? ( orderDto.location.city + ',' ) : ""}}
                                        {{orderDto.location.postCode ? ( orderDto.location.postCode + ',' ) : ""}}
                                        {{orderDto.location.country}}
                                    </div>
                                </li>
                                <li>
                                    <div class="info">  {{orderDto.location.phone}} </div>
                                </li>
                                <li>
                                    <div class="info">  {{orderDto.location.email}} </div>
                                </li>
                            </ul>
                        </div>

                    </td>
                    <td class="center-column">
                       
                    </td>
                    <td class="center-column">
                            <div class="order-info-by-order">
                                <div class="logo-section">
                                    <img [src]="orderDto.logoUrl? orderDto.logoUrl : '../../../../assets/images/greatlab-logo.png'"
                                        alt="">
                                </div>
                            </div>
                    </td>
                </tr>
            </tbody>

        </table>
        <table class="content-body">
            <colgroup>
                <col style="width:50%">
                <col style="width:50%">
            </colgroup>
            <tbody>
                <tr>
                    <td class="center-column border-r d-flex ">
                        <div class="order-details-content">
                            <div class="order-title">
                                Order ID {{orderDto.code}}
                            </div>

                            <div class="order-info w-100">
                                <table class="table-tag">
                                    <colgroup>
                                        <col style="width:40%">
                                        <col style="width:60%">
                                    </colgroup>
                                    <tbody>
                                        <tr *ngIf="orderDto.tags.length > 0">
                                            <td class="tbody">Tag</td>
                                            <td class="tbody">
                                                <ng-container *ngFor="let tag of orderDto.tags">
                                                    {{tag.name}}
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <div class="order-info-by-order">
                                <table class="table-tag w-100" *ngIf="orderDto.status.id > 1">
                                    <colgroup>
                                        <col style="width:40%">
                                        <col style="width:60%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td class="order-date">Deadline Date:</td>
                                            <td class="order-date"> {{orderDto.deadlineDate | date:(orderDto.dateFormat ? orderDto.dateFormat : 'dd/MM/yy')}}</td>
                                        </tr>
                                        <tr>
                                            <td class="order-date">Due Date:</td>
                                            <td class="order-date">{{orderDto.deliveryDate | date:(orderDto.dateFormat ? orderDto.dateFormat : 'dd/MM/yy')}}</td>
                                        </tr>
                                    </tbody>

                                </table>
                                <!-- <ng-container *ngIf="orderDto.status.id > 1">
                                    <div class="table-info-title"><b>Deadline Date:</b></div>
                                    <div class="table-info-title"><b>Due Date: {{orderDto.deliveryDate | date:
                                            'dd/MM/YY'}}</b></div>
                                </ng-container> -->



                                <div class="table-info-title">Patient details</div>
                                <table class="table-base">
                                    <colgroup>
                                        <col style="width:40%">
                                        <col style="width:60%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td class="thead">Practice</td>
                                            <td class="tbody">{{orderDto.practiceName}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">Address</td>
                                            <td class="tbody">{{orderDto.practice.country}},
                                                {{orderDto.practice.address}}, {{orderDto.practice.routNumber}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">Doctor</td>
                                            <td class="tbody">{{orderDto.practiceDentistListName?
                                                orderDto.practiceDentistListName : orderDto.dentistName}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">Patient</td>
                                            <td class="tbody">{{orderDto.patientName}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="thead">ID</td>
                                            <td class="tbody">{{orderDto.patient.id}}</td>
                                        </tr> -->
                                        <!-- <tr>
                                            <td class="thead">Gender</td>
                                            <td class="tbody">{{orderDto.patient.gender}}</td>
                                        </tr> -->
                                        <!-- <tr>
                                            <td class="thead">DOB</td>
                                            <td class="tbody">{{orderDto.patient.dateOfBirth | date: 'dd/MM/YY'}}</td>
                                        </tr> -->
                                   
                                 
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="order-info-by-order"
                                *ngIf="orderHelperService.checkIsMultiOrderType(orderDto.categoryId)">
                                <div class="table-info-title">Order details</div>
                                <table class="table-base">
                                    <colgroup>
                                        <col style="width:40%">
                                        <col style="width:60%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td class="thead">Category</td>
                                            <td class="tbody">{{orderDto.category.name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                            <!-- <div class="order-info-by-order"
                                *ngIf="!(orderHelperService.checkIsMultiOrderType(orderDto.categoryId))">
                                <div class="table-info-title">Order details</div>
                                <table class="table-base">
                                    <colgroup>
                                        <col style="width:40%">
                                        <col style="width:60%">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td class="thead">Category</td>
                                            <td class="tbody">{{orderDto.category.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">Product</td>
                                            <td class="tbody">{{orderDto.orderProducts[0].product.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">SKU</td>
                                            <td class="tbody">{{orderDto.orderProducts[0].sku}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">Teeth </td>
                                            <td class="tbody">{{orderDto.orderProducts[0].teeth}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thead">Incisal shade </td>
                                            <td class="tbody">{{orderDto.orderProducts[0].shade}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                            <div class="order-info-by-order">
                                <div class="table-info-title">Custom</div>
                                <table class="table-base">
                                    <colgroup>
                                        <col style="width:40%">
                                        <col style="width:60%">
                                    </colgroup>
                                    <tbody>
                                        <ng-container *ngIf="checkIsOrthoColor()  &&  orderDto.orderProducts.length == 1">
                                            <tr>
                                                <td class="thead">Upper colours</td>
                                                <td class="tbody">
                                                    {{orderDto.orderProducts[0].orderProductColor.lowerColor1}}
                                                    {{orderDto.orderProducts[0].orderProductColor.lowerColor2}}
                                                    {{orderDto.orderProducts[0].orderProductColor.lowerColor3}}
                                                    {{orderDto.orderProducts[0].orderProductColor.lowerColor4}}
                                                    {{orderDto.orderProducts[0].orderProductColor.lowerColor5}}
                                        
                                                </td>
                                            </tr>
                                        
                                            <tr>
                                                <td class="thead">Lower colours</td>
                                                <td class="tbody">
                                                    {{orderDto.orderProducts[0].orderProductColor.upperColor1}}
                                                    {{orderDto.orderProducts[0].orderProductColor.upperColor2}}
                                                    {{orderDto.orderProducts[0].orderProductColor.upperColor3}}
                                                    {{orderDto.orderProducts[0].orderProductColor.upperColor4}}
                                                    {{orderDto.orderProducts[0].orderProductColor.upperColor5}}
                                                </td>
                                            </tr>
                                        </ng-container>
            


                                        <ng-container *ngIf="checkIsOrtho() && orderDto.orderOrthodonticsInformations">
                                            <tr>
                                                <td class="thead">Movement restrictions</td>
                                                <td class="tbody">
                                                    {{orderDto.orderOrthodonticsInformations?.teethRestrictedFromMoving}}    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="thead">Shouldn’t receive attachments</td>
                                                <td class="tbody">
                                                    {{orderDto.orderOrthodonticsInformations?.teethNotReceiveAttachments}}    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="thead">Extractions</td>
                                                <td class="tbody">
                                                    {{orderDto.orderOrthodonticsInformations?.teethExtracted}}    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="thead">Limit steps</td>
                                                <td class="tbody">
                                                    {{orderDto.orderOrthodonticsInformations?.limitOfSteps}}    
                                                </td>
                                            </tr>
                                        </ng-container>


                                        <tr>
                                            <td class="thead">Scans or Impressions</td>
                                            <td class="tbody">{{getCurrentScan(orderDto.scansImpressions)}}</td>
                                        </tr>

                                        <tr>
                                            <td class="thead">Status</td>
                                            <td class="tbody">{{orderDto.status?.name}} </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>



                            <div class="teeth-product">
                                <ng-container *ngIf="orderDto.categoryId !== 9">
                                    <!-- is multi {{orderHelperService.checkIsMultiOrderType(orderDto.categoryId)}}
        
        
                                    is signl {{!(orderHelperService.checkIsMultiOrderType(orderDto.categoryId))}} -->
                                    <div class="teeth-section" *ngIf="(checkIsOrthoColor()) &&  orderDto.orderProducts.length == 1">
                                        <ngx-order-select-orthodontic-colours [arch]="arch" [disabled]="true" [value]="orderDto.orderProducts[0]"
                                        [product]="orderDto.orderProducts[0]"></ngx-order-select-orthodontic-colours>
                             
                                    </div>
                                    
                                    <div class="teeth-section" *ngIf="!checkIsOrthoColor()">
                                        <ngx-multiselect-teeth-control #teethControl [isSingle]="false" [product]="productInfo" [teethType] = "orderDto.location.teethType"
                                            [data]="orderDto" [isHideLegend]="true">
                                        </ngx-multiselect-teeth-control>
                             
                                    </div>
                                    <!-- <div class="teeth-section"
                                        *ngIf="!(orderHelperService.checkIsMultiOrderType(orderDto.categoryId))">
        
                                        <div class="singl-teeth ">
                                            <div class="teeth">
                                                <img src="../../../../assets/images/lower-teeth.png" alt="">
                                                <div class="checkbox"
                                                    [ngClass]="{'active' : orderHelperService.checkIsTeethActive('Lower', orderDto.orderProducts[0])}">
        
                                                </div>
                                            </div>
                                            <div class="teeth">
                                                <img src="../../../../assets/images/upper-teeth.png" alt="">
                                                <div class="checkbox"
                                                    [ngClass]="{'active' : orderHelperService.checkIsTeethActive('Upper', orderDto.orderProducts[0])}">
        
                                                </div>
                                            </div>
                                        </div>
        
        
                                    </div> -->
                                    <!-- <table class="table-base">
                                        <tbody>
                                            <tr>
                                                <th class="tbody sm">NAME</th>
                                                <th class="tbody sm">SKU</th>
                                                <th class="tbody sm">SHADE</th>
                                                <th class="tbody sm">TEETH</th>
                                            </tr>
                                            <tr *ngFor="let product of orderDto.orderProducts">
                                                <td class="thead sm">{{product.product.name}}</td>
                                                <td class="thead sm">{{product.product.sku}}</td>
                                                <td class="thead sm">{{product.shade}}</td>
                                                <td class="thead sm">{{product.teeth}}</td>
    
                                            </tr>
                                        </tbody>
                                    </table> -->
                                </ng-container>
                            </div>


                        </div>









                    </td>
                    <td class="center-column p-1">
                        <div class="order-info-by-order comment-order">
                            <div class="table-info-title">Doctor instructions</div>


                        

                            <div class="textareat-container" *ngIf="orderDto.stages.length == 0">
                                <div class="p-3" *ngIf="checkIsOrtho() && orderDto.orderOrthodonticsInformations">
                                    <div class="comment-item">
                                        <div class="comment-label">
                                            Crowding:
                                        </div>
                                        <div class="comment-value">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowing}}
                                        </div>
                                        <div class="comment-text">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowingComment}}
                                        </div>
                                    </div>
                                    <div class="comment-item">
                                        <div class="comment-label">
                                            Crowding:
                                        </div>
                                        <div class="comment-value">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowing}}
                                        </div>
                                        <div class="comment-text">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowingComment}}
                                        </div>
                                    </div>
                                    <div class="comment-item">
                                        <div class="comment-label">
                                            Crowding:
                                        </div>
                                        <div class="comment-value">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowing}}
                                        </div>
                                        <div class="comment-text">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowingComment}}
                                        </div>
                                    </div>
                                    <div class="comment-item">
                                        <div class="comment-label">
                                            Crowding:
                                        </div>
                                        <div class="comment-value">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowing}}
                                        </div>
                                        <div class="comment-text">
                                            {{orderDto.orderOrthodonticsInformations.resolveCrowingComment}}
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <pre>{{orderDto.description}}</pre>
                                </div>

                             
                            </div>
                            <div class="textareat-container" *ngIf="orderDto.stages.length > 0">
                                <div class="stage-container" *ngFor="let stage of orderDto.stages">
                                    <div class="stage-name">
                                        {{stage.name}}
                                    </div>
                                </div>
                            </div>


                        </div>


                    </td>
                </tr>
            </tbody>
        </table>
        <div class="prodicts-section">
            <table class="product-list">
                <colgroup>
                    <col style="width:25%">
                    <col style="width:25%">
                    <col style="width:20%">
                    <col style="width:15%">
                    <col style="width:15%">
                </colgroup>
                <tbody>
                    <tr>
                        <th class="thead">Product</th>
                        <th class="thead">Material</th>
                        <th class="thead">Standard</th>
                        <th class="thead">Shade</th>
                        <th class="thead">Teeth/Arch</th>
                    </tr>
                    <tr *ngFor="let item of orderDto.orderProducts">
                        <td class="tbody">{{item.productName}}</td>
                        <td class="tbody">{{item.materialName}}</td>
                        <td class="tbody">{{item.standardTierName}}</td>
                        <td class="tbody">{{item.shade ? item.shade : '-'}}
                            <br>
                            <small class="category-label">{{item.shadeSystem}}</small>
                        </td>
                        <td class="tbody">{{item.teeth ? convertTeethNotation(orderDto.location.teethType, item.teeth) : item.arch}}</td>
                    </tr>
                </tbody>
    
            </table>
        </div>
        <table class="approve-to-release w-100">
            <colgroup>
                <col style="width:40%">
                <col style="width:60%">
            </colgroup>
            <tbody>
                <tr>
                    <td class="tbody text-left">
                        <div class="barcode-section p-1 ">
                            <div class="order-info-by-order">
                                <ngx-generic-barcode [isConfig]="false" [code]="orderDto.code"> </ngx-generic-barcode>
                            </div>
                        </div>
                     

                    </td>
                    <td class="tbody text-right">
                         <span class="approve" [ngClass]="{'empty' : !orderDto.qcApprovedBy}">Approved for release: <b
                                        *ngIf="orderDto.qcApprovedBy">{{orderDto.qcApprovedBy}} {{orderDto.qcApprovedAt
                                        | date: 'hh:mm a dd/MM/YY'}}</b></span>
                        </td>
                </tr>
            </tbody>

        </table>
       
            <table class="footer-content">
                <colgroup>
                    <!-- <col style="width:20%"> -->
                    <col style="width:80%">
                    <col style="width:20%">
                </colgroup>
                <tbody>
                    


                     <tr>
                        <td class="center-column p-footer">
                            <div class="footer-xs-text" [innerHtml]="!isDeffaultFiitertemplate ? orderDto.locationDocumentSettings.docketTermsConditions : docketTermsConditions">

                            </div>
                            <div class="footer-md-text text-center" [innerHtml]="!isDeffaultFiitertemplate ? orderDto.locationDocumentSettings.docketFooter : docketFooter"></div>
                        </td>
                        <td class="center-column">
                            <div class="footer-logo">
                                <img [src]="!isDeffaultFiitertemplate ? orderDto.locationDocumentSettings.docketFooterLogo : docketFooterLogo" alt="">
                            </div>
                            <div class="footer-logo">
                                <div class="powered">
                                    <div class="text">Powered by</div>
                                    <img src="../../../../assets/images/greatlab-logo.png"
                                        alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
    
            </table>
    




    </div>
</div>
