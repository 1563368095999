import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { finalize } from 'rxjs/operators';
import { AuthServiceProxy, ProductServiceProxy, CompanyServiceProxy, LocationServiceProxy, UserConfirmationLinkResult } from '../../../../shared/service-proxies/event-service-proxy';
import { ModalCreateMemberComponent } from '../modal-create-member/modal-create-member.component';

@Component({
  selector: 'ngx-modal-gen-invite-link',
  templateUrl: './modal-gen-invite-link.component.html',
  styleUrls: ['./modal-gen-invite-link.component.scss']
})
export class ModalGenInviteLinkComponent implements OnInit {
  inviteUrl: any ;
  @Input() isLink: any; 
  @Input() userId: any; 
  @Input() title: any; 
  @Input() desc: any; 
  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthServiceProxy,
    protected dialogRef: NbDialogRef<ModalGenInviteLinkComponent>,
  ) { }

  ngOnInit(): void {
    
    if (this.isLink) {
      this.inviteUrl = this.userId;
    }  else {
      this.getLink();
    }
  }
  closeLink() {
    this.dialogRef.close(true)
  }

  getLink() {
    this._authService.generateConfirmEmailLink(this.userId).pipe(
      finalize(() => {
      }))
      .subscribe(
        (resp: UserConfirmationLinkResult) => {
          this.inviteUrl = resp.link;
        },
        (error: any) => { }
      )
  }
}
